import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { PinataSDK } from "pinata";
import QRCode from 'qrcode.react'; // Импортируем компонент QRCode
import imageCompression from 'browser-image-compression';

const PINATA_API_KEY = 'b1adb65f27feca2b1cdc';  // Replace with your Infura Project ID
const PINATA_SECRET_API_KEY = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5mb3JtYXRpb24iOnsiaWQiOiJlZGE3OTU1ZS01NThhLTQ0YjItYmUwYS0xMmE5NTRhYmYxZGMiLCJlbWFpbCI6InJvaW92ZXJAcHJvdG9uLm1lIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsInBpbl9wb2xpY3kiOnsicmVnaW9ucyI6W3siZGVzaXJlZFJlcGxpY2F0aW9uQ291bnQiOjEsImlkIjoiRlJBMSJ9LHsiZGVzaXJlZFJlcGxpY2F0aW9uQ291bnQiOjEsImlkIjoiTllDMSJ9XSwidmVyc2lvbiI6MX0sIm1mYV9lbmFibGVkIjpmYWxzZSwic3RhdHVzIjoiQUNUSVZFIn0sImF1dGhlbnRpY2F0aW9uVHlwZSI6InNjb3BlZEtleSIsInNjb3BlZEtleUtleSI6ImIxYWRiNjVmMjdmZWNhMmIxY2RjIiwic2NvcGVkS2V5U2VjcmV0IjoiNmUzY2RjNmExNWU1YThhNWUxNTkwMmI5NGUwMWM1MjgyY2U0ODM3ODBhMWY0ZjcwMzQxYzI3NTFjYjVhNTlkZCIsImV4cCI6MTc2NDQxMjU3Nn0.FD15exn56ICeP46SOWpCXkOqpgsR1Evh9Cde9-xnUjI';  // Replace with your Infura Project Secret
const pinata = new PinataSDK({
  pinataJwt: PINATA_SECRET_API_KEY,
  pinataGateway: "https://magenta-able-dormouse-779.mypinata.cloud/",
});
const compressAndRemoveMetadata = async (file) => {
  const options = {
    maxSizeMB: 1, // Максимальный размер файла — 1 МБ
    maxWidthOrHeight: 1920, // Изменить размер изображения до 1920x1920
    useWebWorker: true, // Включить многопоточность для повышения производительности
    exifOrientation: true, // Сохраняет правильную ориентацию, но удаляет метаданные
  };

  try {
    const compressedFile = await imageCompression(file, options); // Сжимает файл
    return compressedFile; // Возвращает обработанный файл
  } catch (error) {
    console.error('Ошибка при удалении метаданных и сжатии:', error);
    throw new Error('Ошибка удаления метаданных и сжатия.');
  }
};
const UploadService = () => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploadedUrls, setUploadedUrls] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [comment, setComment] = useState('');
  const [galleryJsonUrl, setGalleryJsonUrl] = useState(null); // URL for the gallery JSON file
  const [expirationTime, setExpirationTime] = useState(12); // Default to 2
  const [expirationUnit, setExpirationUnit] = useState('M'); // Default to days
  const [removeMetadata, setRemoveMetadata] = useState(false);
  const [loadingx, setLoadingx] = useState(true); 
  const [filesUploaded, setFilesUploaded] = useState(false);
  const [isdelChecked, setIsdelChecked] = useState(false);
  const [error, setError] = useState(""); // Error message state

   const handleExpirationChange = (event) => {
    const value = event.target.value;

    // Validate the input value based on the selected unit
    let maxAllowedValue;
    switch (expirationUnit) {
      case "h": // Hours
        maxAllowedValue = 24;
        break;
      case "d": // Days
        maxAllowedValue = 365;
        break;
      case "w": // Weeks
        maxAllowedValue = 52;
        break;
      case "M": // Months
        maxAllowedValue = 12;
        break;
      default:
        maxAllowedValue = Infinity;
    }

    if (value === "" || (Number(value) > 0 && Number(value) <= maxAllowedValue)) {
      setExpirationTime(value); // Update input if valid
      setError(""); // Clear error
    } else {
      setError(
        `Максимально допустимое значение для "${getUnitLabel(
          expirationUnit
        )}" - ${maxAllowedValue}.`
      );
    }
  };

  const handleUnitChange = (event) => {
    setExpirationUnit(event.target.value);
    setError(""); // Reset error when unit changes
  };

  const getUnitLabel = (unit) => {
    switch (unit) {
      case "h":
        return "Часы";
      case "d":
        return "Дни";
      case "w":
        return "Недели";
      case "M":
        return "Месяцы";
      default:
        return "";
    }
  };

  
  
    // Simulate page load
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoadingx(false);
    }, 3000); // Simulate a 3-second page load

    return () => clearTimeout(timer); // Cleanup timer
  }, []);
   const handleCheckboxChange = (event) => {
    setIsdelChecked(event.target.checked);
  };
  // Loader component
  const Loader = () => (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-900 text-white">
      <div className="flex items-center space-x-2 bg-gradient-to-r from-blue-500 to-purple-500 text-white px-4 py-2 rounded-lg shadow-lg">
        <span className="text-2xl font-semibold">PhotoBunker</span>
        <div className="flex space-x-1">
          <span className="w-2 h-2 bg-white rounded-full animate-bounce" style={{ animationDelay: "0s" }}></span>
          <span className="w-2 h-2 bg-white rounded-full animate-bounce" style={{ animationDelay: "1.2s" }}></span>
          <span className="w-2 h-2 bg-white rounded-full animate-bounce" style={{ animationDelay: "1.4s" }}></span>
        </div>
      </div>
      <p className="mt-4 text-sm">Загрузка... Ожидайте</p>
    </div>
  );

const handleFileChange = async (event) => {
  const selectedFiles = Array.from(event.target.files);

  try {
    const processedFiles = await Promise.all(
      selectedFiles.map(async (file) => {
        if (removeMetadata) {
          return await compressAndRemoveMetadata(file);
        }
        return file; // Если флажок не выбран, возвращаем исходный файл
      })
    );

    if (processedFiles.length + files.length <= 10) {
      setFiles((prevFiles) => [...prevFiles, ...processedFiles]);
    } else {
      alert('Можно загрузить не более 10 файлов.');
    }
  } catch (error) {
    console.error('Ошибка обработки файлов:', error);
    alert('Не удалось обработать файлы.');
  }
};


   const createGalleryJson = (imageUrls,descrx,idxc) => {
     const advancedDelChecked = isdelChecked;
     console.log(advancedDelChecked);
    return JSON.stringify({
      title: "Gallery",
      timex: advancedDelChecked ? 1 : 3,
      description: descrx,
      images: imageUrls.map(url => ({ url })),
      xec: idxc.map(id => ({ id })),
    }, null, 2);
  };
  
  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFiles = Array.from(event.dataTransfer.files);
    if (droppedFiles.length + files.length <= 10) {
      setFiles((prevFiles) => [...prevFiles, ...droppedFiles]);
    } else {
      alert('You can only upload a maximum of 10 files.');
    }
  };
  
    const handleCopyUrl = () => {
  navigator.clipboard.writeText(`https://photobunker.pro/gallery/${encodeURIComponent(galleryJsonUrl)}`);
  showNotification("Ссылка скопирована в буфер обмена!");
};

const showNotification = (message) => {
  // Create the notification element
  const notification = document.createElement("div");
  notification.textContent = message;

  // Add Tailwind classes for styling
  notification.className = `
    fixed bottom-5 right-5 bg-gray-800 text-white text-sm px-4 py-2 rounded shadow-lg 
    opacity-0 transition-opacity duration-300 ease-in-out
  `;

  // Append to the document
  document.body.appendChild(notification);

  // Show notification
  setTimeout(() => {
    notification.classList.add("opacity-100");
  }, 10);

  // Remove after 3 seconds
  setTimeout(() => {
    notification.classList.remove("opacity-100");
    setTimeout(() => {
      notification.remove();
    }, 300); // Wait for fade-out animation to complete
  }, 3000);
};


  const handleDeleteNow = () => {
    window.location.reload();
  };
  
  const handleDragOver = (event) => {
    event.preventDefault();
  };

const handleUpload = async () => {
    setLoading(true);
    const imageUrls = [];
    const idxc = [];

    try {
      // Step 1: Upload each image and get its URL
      const uploadPromises = files.map(async (file) => {
        const response = await pinata.upload.file(file);
        imageUrls.push(`https://chocolate-internal-scorpion-907.mypinata.cloud/files/${response.cid}`);
        idxc.push(`${response.id}`);
      });
      await Promise.all(uploadPromises);

      // Step 2: Create JSON content for the gallery
      const jsonContent = createGalleryJson(imageUrls,comment,idxc);
      const jsonBlob = new Blob([jsonContent], { type: 'application/json' });
      const jsonFile = new File([jsonBlob], 'gallery.json', { type: 'application/json' });

      // Step 3: Upload JSON file to IPFS
      const jsonResponse = await pinata.upload.file(jsonFile);
      console.log('test')
            console.log(jsonResponse.cid)

   
    //const datax = await pinata.gateways.get(jsonResponse.cid);
    //console.log(datax)

    setGalleryJsonUrl(`${jsonResponse.cid}`);
    //console.log(datax)
    setFilesUploaded(true);

  

      
      console.log(jsonResponse.cid)
    } catch (error) {
      console.error('Error uploading files:', error);
      alert('Failed to upload files.');
    } finally {
      setLoading(false);
      setFiles([]);
    }
  };
  const Loaderx = () => (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-900 text-white">
      <div className="flex items-center space-x-2 bg-gradient-to-r from-blue-500 to-purple-500 text-white px-4 py-2 rounded-lg shadow-lg">
        <span className="text-2xl font-semibold">PhotoBunker</span>
        <div className="flex space-x-1">
          <span className="w-2 h-2 bg-white rounded-full animate-bounce" style={{ animationDelay: "0s" }}></span>
          <span className="w-2 h-2 bg-white rounded-full animate-bounce" style={{ animationDelay: "0.2s" }}></span>
          <span className="w-2 h-2 bg-white rounded-full animate-bounce" style={{ animationDelay: "0.4s" }}></span>
        </div>
      </div>
      <p className="mt-4 text-sm">Загрузка... Ожидайте</p>
    </div>
  );
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
    <div className="flex flex-col items-center p-1 bg-gradient-to-r from-gray-800 to-black text-white min-h-screen">
      {/* Header Section */}
    <header className="w-full flex md:justify-center justify-between items-center flex-col p-2 bg-gradient-to-r from-gray-700 to-gray-900 mb-4">

<div className="flex justify-between items-center w-full sm:mt-0 mt-5">
  {/* Center Content */}
  <div className="flex flex-1 justify-start items-center">
    {/* "PhotoBunker" as a button-like text */}
    <a href="/">
      <div className="flex items-center space-x-2 cursor-pointer bg-gradient-to-r from-blue-500 to-purple-500 text-white px-4 py-2 rounded-lg shadow-lg hover:opacity-75">
        <span className="text-2xl font-semibold">PhotoBunker</span>
        {/* Upload Icon SVG */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 40 20"
          fill="currentColor"
          className="w-6 h-6"
        >
          <path
            fillRule="evenodd"
            d="M10 3a1 1 0 011 1v6.586l3.707-3.707a1 1 0 111.414 1.414l-5 5a1 1 0 01-1.414 0l-5-5a1 1 0 111.414-1.414L9 10.586V4a1 1 0 011-1z"
            clipRule="evenodd"
          />
          <path d="M10 13a7 7 0 100-14 7 7 0 000 14z" />
        </svg>
      </div>
    </a>
  </div>

  {/* Right-aligned Telegram SVG */}
  <div className="flex flex-[0.1] justify-end">
    <a href="https://t.me/photobunker_bot" target="_blank" rel="noopener noreferrer">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        aria-label="Telegram"
        viewBox="0 0 512 512"
        className="w-6 h-6 cursor-pointer hover:opacity-75"
      >
        <rect width="512" height="512" fill="#37aee2" rx="15%"></rect>
        <path fill="#c8daea" d="M199 404c-11 0-10-4-13-14l-32-105 245-144"></path>
        <path fill="#a9c9dd" d="M199 404c7 0 11-4 16-8l45-43-56-34"></path>
        <path
          fill="#f6fbfe"
          d="M204 319l135 99c14 9 26 4 30-14l55-258c5-22-9-32-24-25L79 245c-21 8-21 21-4 26l83 26 190-121c9-5 17-3 11 4"
        ></path>
      </svg>
    </a>
  </div>
    {/* Right-aligned Telegram SVG */}
  <div className="flex flex-[0.1] pl-[10px] justify-center">
    <a href="/" target="_blank" rel="noopener noreferrer">
      <svg
      width="44px"
      height="40px"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.5915 3.88444C13.6002 3.32107 14.7626 3 16 3C17.2374 3 18.3998 3.32107 19.4085 3.88444L20.1464 3.14645C20.3417 2.95118 20.6583 2.95118 20.8536 3.14645C21.0488 3.34171 21.0488 3.65829 20.8536 3.85355L20.2612 4.44595C21.9266 5.72558 23 7.73743 23 10H9C9 7.73743 10.0734 5.72558 11.7388 4.44595L11.1464 3.85355C10.9512 3.65829 10.9512 3.34171 11.1464 3.14645C11.3417 2.95118 11.6583 2.95118 11.8536 3.14645L12.5915 3.88444ZM14 7C14 7.55228 13.5523 8 13 8C12.4477 8 12 7.55228 12 7C12 6.44772 12.4477 6 13 6C13.5523 6 14 6.44772 14 7ZM19 8C19.5523 8 20 7.55228 20 7C20 6.44772 19.5523 6 19 6C18.4477 6 18 6.44772 18 7C18 7.55228 18.4477 8 19 8Z"
          fill="#87C527"
        ></path>
        <path d="M5 12.5C5 11.6716 5.67157 11 6.5 11C7.32843 11 8 11.6716 8 12.5V18.5C8 19.3284 7.32843 20 6.5 20C5.67157 20 5 19.3284 5 18.5V12.5Z" fill="#87C527"></path>
        <path d="M12 24V27.5C12 28.3284 12.6716 29 13.5 29C14.3284 29 15 28.3284 15 27.5V24H17V27.5C17 28.3284 17.6716 29 18.5 29C19.3284 29 20 28.3284 20 27.5V24H21C22.1046 24 23 23.1046 23 22V11H9V22C9 23.1046 9.89543 24 11 24H12Z" fill="#87C527"></path>
        <path d="M24 12.5C24 11.6716 24.6716 11 25.5 11C26.3284 11 27 11.6716 27 12.5V18.5C27 19.3284 26.3284 20 25.5 20C24.6716 20 24 19.3284 24 18.5V12.5Z" fill="#87C527"></path>
      </g>
    </svg>
    </a>
  </div>
  
</div>

            </header>
      {!filesUploaded && ( <h1 className="text-white text-2xl sm:text-2xl py-2 text-gradient ">Загрузите файлы в IPFS</h1>  )}
                  {!filesUploaded && (
      <div className="border-dashed border-4 border-white p-10 w-full max-w-lg text-center cursor-pointer bg-gray-900 rounded-lg shadow-lg hover:opacity-75 transition"
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        <input
          type="file"
          multiple
          onChange={handleFileChange}
          className="hidden"
          id="file-upload"
        />
        <label htmlFor="file-upload" className="block text-xl font-semibold">
          Перетащите файлы или кликните для загрузки
        </label>
      </div>
   )}
{/* Expires Section */}
{/* Stylish Settings Box */}
{!filesUploaded && (
<div style={{
    background: 'linear-gradient(to right, #111827, rgb(55 65 81))',
    border: '1px solid rgb(55 65 81)',
  }} className="mt-2 p-6 bg-gradient-to-r from-gray-100 to-gray-200 dark:from-gray-800 dark:to-gray-900 border border-gray-300 dark:border-gray-700 rounded-2xl shadow-2xl max-w-lg w-full space-y-4">
{/*  <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-200 mb-4">Настройки</h2> */}

  {/* Expires Section */}
  <div style={{
    background: 'linear-gradient(to right, #111827, rgb(55 65 81))',
    border: '1px solid rgb(55 65 81)',
  }} className="flex flex-wrap items-center space-y-3 sm:space-y-0 sm:space-x-3 p-4 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-xl shadow-md">
    <label
      style={{
      color:'white',
  }}
      htmlFor="expires"
      className="block sm:inline text-lg font-medium text-gray-700 dark:text-gray-300 mb-2 sm:mb-0"
    >
      Срок хранения:
    </label>
    <input
      type="text"
      value={expirationTime}
      onChange={handleExpirationChange}
style={{
    color:'white',
    background: 'linear-gradient(to right, #111827, rgb(55 65 81))',
    border: '1px solid rgb(55 65 81)',
  }}
      className="w-full sm:w-20 p-2 text-sm font-medium text-gray-900 bg-gray-50 border border-gray-300 rounded-lg shadow-inner focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-600 dark:text-white dark:border-gray-500"
    />
    <select
      value={expirationUnit}
      onChange={handleUnitChange}
style={{
    color:'white',
    background: 'linear-gradient(to right, #111827, rgb(55 65 81))',
    border: '1px solid rgb(55 65 81)',
  }}
      className="w-full sm:w-auto p-2 text-sm font-medium text-gray-900 bg-gray-50 border border-gray-300 rounded-lg shadow-inner focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-600 dark:text-white dark:border-gray-500"
    >
      <option style={{
    color:'white',
    background: 'rgb(55, 65, 81)',
    border: '1px solid rgb(55 65 81)',
  }} value="h">Часы</option>
      <option style={{
    color:'white',
    background: 'rgb(55, 65, 81)',
    border: '1px solid rgb(55 65 81)',
  }} value="d">Дни</option>
      <option style={{
    color:'white',
    background: 'rgb(55, 65, 81)',
    border: '1px solid rgb(55 65 81)',
  }} value="w">Недели</option>
      <option style={{
    color:'white',
    background: 'rgb(55, 65, 81)',
    border: '1px solid rgb(55 65 81)',
  }} value="M">Месяцы</option>
    </select>
{error && (
               <div className="flex items-center text-red-500 text-sm mt-2 sm:mt-0">
          {/* Error Icon (SVG) */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 32 32"
            style={{ marginRight: "8px" }}
          >
            <circle cx="16" cy="16" r="16" style={{ fill: "#D72828" }} />
            <path
              d="M14.5,25h3v-3h-3V25z M14.5,6v13h3V6H14.5z"
              style={{ fill: "#E6E6E6" }}
            />
          </svg>
          <p>{error}</p>
        </div>
      )}
</div>


  
  {/* Additional Settings */}
  <div style={{
    background: 'linear-gradient(to right, #111827, rgb(55 65 81))',
    border: '1px solid rgb(55 65 81)',
  }} className="mt-2 p-6 bg-gray-200 bg-gradient-to-r from-gray-300 to-gray-200 dark:from-gray-800 dark:to-gray-900 border border-gray-300 dark:border-gray-700 rounded-2xl shadow-2xl max-w-lg w-full space-y-4">
   {/* <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-200 mb-2">Дополнительные настройки</h3>*/}
    <ul className="space-y-2">
    <li className="flex items-center">
        <input
          id="advanced-del"
          type="checkbox"
          checked={isdelChecked}
          onChange={handleCheckboxChange}
        style={{
    color:'white',
    background: 'linear-gradient(to right, #111827, rgb(55 65 81))',
    border: '1px solid rgb(55 65 81)',
  }}
          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
        />
        <label style={{
    color:'white',
  }}
          htmlFor="advanced-encryption"
          className="ml-3 text-sm font-medium text-gray-800 dark:text-gray-300"
        >
          Удалить после просмотра
        </label>
      </li>
      <li className="flex items-center">
       <input
  id="remove-metadata"
  type="checkbox"
style={{
    color:'white',
    background: 'linear-gradient(to right, #111827, rgb(55 65 81))',
    border: '1px solid rgb(55 65 81)',
  }}
  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
  checked={removeMetadata}
  onChange={() => setRemoveMetadata(!removeMetadata)}
/>
<label
  htmlFor="remove-metadata"
style={{
    color:'white',
  }}
  className="ml-3 text-sm font-medium text-gray-800 dark:text-gray-300"
>
  Сжать и удалить метаданные
</label>
      </li>
    </ul>
  </div>
</div>

)}

      {files.length > 0 && (
        <div className="mt-6 w-full max-w-lg">
          <h2 className="text-2xl font-bold mb-4">Ваши Файлы:</h2>
          <div className="grid grid-cols-2 gap-4">
            {files.map((file, index) => (
    <div key={index} className="relative">
              <img
                src={URL.createObjectURL(file)}
                alt="Preview"
                className="w-full h-32 object-cover rounded-lg cursor-pointer"
                onClick={() => {
                  setSelectedImage(URL.createObjectURL(file));
                  setIsPopupOpen(true);
                }}
              />
              <span className="absolute top-0 right-0 bg-red-600 text-white text-xs px-2 py-1 rounded-full">
                {file.name}
              </span>
            </div>
            ))}
          </div>
{/* Hint Section */}
{/* Hint Section */}
<div className="flex flex-col items-center mt-6">
  <p className="text-xs text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-yellow-500 shadow-lg shadow-green-500/50 p-3 mx-4 rounded-md">
    Метаданные были успешно очищены при загрузке. Ваши файлы безопасны и защищены от лишней информации.
  </p>
</div>

          <div style={{
    background: 'linear-gradient(to right, #111827, rgb(55 65 81))',
    border: '1px solid rgb(55 65 81)',
  }} className="mt-3 w-full mb-4 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600">
   
        <div style={{
    background: 'linear-gradient(to right, #111827, rgb(55 65 81))',
    border: '1px solid rgb(55 65 81)',
  }} className="px-4 py-2 bg-white rounded-t-lg dark:bg-gray-800">
          <label htmlFor="comment" className="sr-only">Your comment</label>
          <textarea
          id="comment"
          rows="4"
style={{
    background: 'linear-gradient(to right, #111827, rgb(55 65 81))',
    border: '1px solid rgb(55 65 81)',
    color: 'white',
  }}
          className="w-full px-0 text-sm text-gray-900 bg-white border-0 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
          placeholder="Комментарий..."
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        ></textarea>
        </div>
             <div class="text-xs flex items-center justify-between px-3 py-2 border-t dark:border-gray-600">
         * Комментарии будут добавлены ко всем загружаемым файлам
                  </div>  
             {/* Popup for previewing image and comment */}
      {isPopupOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white p-5 rounded-lg max-w-xl">
            <button
              onClick={() => setIsPopupOpen(false)}
              className="text-red-600 float-right"
            >
              X
            </button>
            <img src={selectedImage} alt="Selected" className="w-full h-auto rounded" />
            <p className="mt-4 text-gray-700">{comment}</p>
          </div>
        </div>
      )}
      </div>
        </div>
      )}
{!filesUploaded && (
      <button
        onClick={handleUpload}
        className={`mt-6 bg-gradient-to-r from-blue-500 to-purple-500 text-white font-semibold py-2 px-6 rounded-lg transition ${
          loading ? 'opacity-50 cursor-not-allowed' : 'hover:opacity-75'
        }`}
        disabled={loading || files.length === 0}
      >
        {loading ? 'Загрузка...' : 'Загрузить файлы'}
      </button>
)}
      {loading && (
        <div className="mt-4 text-center text-lg font-semibold">
          <p>Загрузка... Ожидайте</p>
        </div>
      )}
{galleryJsonUrl && (
  <div className="flex flex-col justify-center items-center mt-3 w-full max-w-lg">
    <h1 className="text-white text-2xl sm:text-2xl py-2 text-gradient text-center">Галерея доступна по ссылке</h1>
  </div>
)}
 {galleryJsonUrl && (
        <div className="flex items-center p-4 mt-6 border border-gray-300 bg-gray-100 rounded-lg shadow-md dark:border-gray-700 dark:bg-gray-800">
          <div className="mr-4">
            <QRCode value={`https://photobunker.pro/gallery/${encodeURIComponent(galleryJsonUrl)}`} size={100} />
          </div>
          <div className="flex-1">
            <textarea
              readOnly
              value={`https://photobunker.pro/gallery/${encodeURIComponent(galleryJsonUrl)}`}
              className="w-full p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded resize-none focus:outline-none dark:bg-gray-700 dark:text-white"
            />
            <div className="flex mt-2 space-x-2">
          <button
  onClick={() => window.open(`${window.location.origin}/gallery/${encodeURIComponent(galleryJsonUrl)}`, '_blank')}
  className="px-4 py-2 text-sm font-semibold text-white bg-blue-500 rounded hover:bg-blue-600"
>
  Открыть галерею
</button>

             <button
  onClick={handleCopyUrl}
  className="p-2 text-sm bg-gradient-to-r from-purple-500 to-pink-500 hover:from-pink-500 hover:to-purple-500 text-white rounded-lg shadow-md transition duration-300"
  style={{ display: "inline-block", whiteSpace: "nowrap" }} // Ensures proper alignment
>
  {/* SVG Icon */}
  <svg
    style={{ display: "inline-block", whiteSpace: "nowrap" }}
    width="20px"
    height="20px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="mr-2" // Adds some space between the icon and text
  >
    <path
      d="M9 12C9 13.3807 7.88071 14.5 6.5 14.5C5.11929 14.5 4 13.3807 4 12C4 10.6193 5.11929 9.5 6.5 9.5C7.88071 9.5 9 10.6193 9 12Z"
      stroke="#1C274C"
      strokeWidth="1.5"
    />
    <path
      d="M14 6.5L9 10"
      stroke="#1C274C"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M14 17.5L9 14"
      stroke="#1C274C"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M19 18.5C19 19.8807 17.8807 21 16.5 21C15.1193 21 14 19.8807 14 18.5C14 17.1193 15.1193 16 16.5 16C17.8807 16 19 17.1193 19 18.5Z"
      stroke="#1C274C"
      strokeWidth="1.5"
    />
    <path
      d="M19 5.5C19 6.88071 17.8807 8 16.5 8C15.1193 8 14 6.88071 14 5.5C14 4.11929 15.1193 3 16.5 3C17.8807 3 19 4.11929 19 5.5Z"
      stroke="#1C274C"
      strokeWidth="1.5"
    />
  </svg>
  {/* Button Text */}
  <span>Копировать</span>
</button>

            </div>
          </div>
        </div>
      )}
      {uploadedUrls.length > 0 && (
        <div className="mt-6 w-full max-w-lg">
          <h2 className="text-2xl font-bold mb-4">Загруженные Файлы:</h2>
          <div>
            {uploadedUrls.map((url, index) => (
              <div key={index} className="text-center mb-4">
                <a
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-400 hover:underline"
                >
                  {url}
                </a>
              </div>
            ))}
          </div>

        </div>
      )}
        <div className="w-full flex flex-col items-center bg-gray-900 py-10 px-4 text-white my-10 rounded-lg shadow-lg max-w-screen-lg mx-auto">
  <h2 className="text-2xl font-bold mb-6 text-center">Почему выбрать наш сервис?</h2>
  <p className="text-md text-center mb-8">
    Наш фотохостинг обеспечивает полную анонимность, безопасность и доступность ваших данных благодаря передовым технологиям хранения.
  </p>

  <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 w-full px-4">
        {/* Advances Encryption Section */}
<div className="flex flex-col items-center bg-gray-800 rounded-lg shadow-md p-6 transform hover:scale-105 hover:shadow-lg transition-transform duration-300 ease-in-out">
  <svg
      fill="#c7c9c5"
      height="46px"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#c7c9c5"
      strokeWidth="0.00512"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        <g id="Hacker_anonymous">
          <path d="M475.3571,413.24a69.9,69.9,0,0,0-39.8845-57.4407l-39.9287-18.7987,21.5791-44.5621a89.4527,89.4527,0,0,0,.0025-77.9684L359.7988,96.0682C317.7933,9.3105,194.2088,9.31,152.2019,96.0666L94.87,214.4745a89.445,89.445,0,0,0,.0049,77.9692l21.581,44.5569L76.5256,355.8a69.898,69.898,0,0,0-39.8831,57.439l-3.612,43.3773a22.5157,22.5157,0,0,0,22.4381,24.3842H456.5337A22.5134,22.5134,0,0,0,478.97,456.6187ZM364,260.1205a107.9746,107.9746,0,0,1-98.1035,107.5V341.1249a9.8965,9.8965,0,0,0-19.793,0v26.4957A107.9746,107.9746,0,0,1,148,260.1205V203.44a28.8192,28.8192,0,0,1,28.8193-28.8193H335.1806A28.8193,28.8193,0,0,1,364,203.44Z"></path>
          <path d="M321.8213,275.9979a9.91,9.91,0,0,0-12.3135,6.6709,13.5776,13.5776,0,0,1-26.0156,0,9.9026,9.9026,0,1,0-18.9844,5.6426,33.3877,33.3877,0,0,0,63.9844,0A9.9125,9.9125,0,0,0,321.8213,275.9979Z"></path>
          <path d="M240.8213,275.9979a9.8908,9.8908,0,0,0-12.3135,6.6709,13.5776,13.5776,0,0,1-26.0156,0,9.9026,9.9026,0,1,0-18.9844,5.6426,33.3877,33.3877,0,0,0,63.9844,0A9.9125,9.9125,0,0,0,240.8213,275.9979Z"></path>
          <path d="M319,227.4384H283a9.8965,9.8965,0,1,0,0,19.7929h36a9.8965,9.8965,0,1,0,0-19.7929Z"></path>
          <path d="M193,247.2313h36a9.8965,9.8965,0,1,0,0-19.7929H193a9.8965,9.8965,0,1,0,0,19.7929Z"></path>
        </g>
      </g>
    </svg>
  <h3 className="text-lg font-semibold">Продвинутое Шифрование</h3>
  <p className="text-sm mt-2 text-gray-400">
    Криптографическая стойкость SHA-256 основана на вычислительной сложности подбора правильного хэша. Даже при использовании современных суперкомпьютеров, перебор всех возможных комбинаций занял бы миллиарды лет. Это делает хэши IPFS устойчивыми к атакам методом полного перебора (brute force).
  </p>
</div>
    {/* Privacy Section */}
<div className="flex flex-col items-center bg-gray-800 rounded-lg shadow-md p-6 transform hover:scale-105 hover:shadow-lg transition-transform duration-300 ease-in-out">
  <svg
      height="46px"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      fill="#000000"
      stroke="#000000"
      strokeWidth="0.00512"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        <g>
          <path
            style={{ fill: '#4ed093' }}
            d="M468.125,128.73C304.284,99.474,234.069,0,234.069,0S163.847,99.474,0.013,128.73 c0,64.367-5.858,277.944,234.056,383.27C473.976,406.675,468.125,193.098,468.125,128.73z"
          ></path>
          <path
            style={{ fill: '#c6ab7b' }}
            d="M234.069,466.88C63.977,384.073,43.61,240.378,41.255,161.786 c95.349-23.792,158.067-69.25,192.814-101.558c34.747,32.308,97.458,77.766,192.807,101.558 C424.528,240.378,404.155,384.073,234.069,466.88z"
          ></path>
          <path
            style={{ fill: '#c2beb7' }}
            d="M410.284,169.867c-1.813,70.95-19.134,203.716-176.215,279.583 c-29.05-14.015-53.306-29.989-73.582-47.191c-27.411-23.173-47.513-48.568-62.293-74.285 c-33.346-58.164-39.32-118.025-40.346-158.106c88.156-21.531,145.384-63.78,176.221-92.92 c15.328,14.483,37.185,32.21,66.118,49.035c26.16,15.183,58.132,29.637,96.284,40.256 C400.981,167.525,405.6,168.725,410.284,169.867z"
          ></path>
          <g>
            <path
              style={{ fill: '#716363' }}
              d="M234.069,76.947c-30.837,29.14-88.066,71.389-176.221,92.92 c0.613,23.74,3.007,54.442,11.742,87.595h164.48V76.947z"
            ></path>
            <path
              style={{ opacity: 0.26, fill: '#231a10' }}
              d="M396.472,166.238c-38.152-10.618-70.124-25.073-96.284-40.256 c-28.934-16.825-50.79-34.552-66.118-49.035v180.515h164.506c8.748-33.153,11.103-63.841,11.709-87.595 C405.6,168.725,400.981,167.525,396.472,166.238z"
            ></path>
            <path
              style={{ opacity: 0.26, fill: '#231a10' }}
              d="M69.589,257.462c6.012,22.824,15.011,46.8,28.604,70.512 c14.78,25.718,34.882,51.113,62.293,74.285c20.276,17.202,44.532,33.176,73.582,47.191V257.462H69.589z"
            ></path>
            <path
              style={{ fill: '#716363' }}
              d="M234.069,449.449c104.49-50.468,147.133-126.108,164.506-191.988H234.069V449.449z"
            ></path>
          </g>
          <path
            style={{ opacity: 0.08, fill: '#161312' }}
            d="M234.069,0c0,0,70.215,99.474,234.056,128.73c0,64.367,5.851,277.944-234.056,383.27V0 z"
          ></path>
        </g>
      </g>
    </svg>
  <h3 className="text-lg font-semibold">Приватность и Безопасность</h3>
  <p className="text-sm mt-2 text-gray-400">
    Мы удаляем метаданные из файлов для защиты вашей приватности. Это предотвращает возможность передачи личной информации, такой как местоположение, время создания и другие данные, которые могут быть встроены в файлы.
  </p>
</div>

    {/* Anonymity Section */}
    <div className="flex flex-col items-center bg-gray-800 rounded-lg shadow-md p-6 transform hover:scale-105 hover:shadow-lg transition-transform duration-300 ease-in-out">
      <svg style={{ height: "46px"}} 
  viewBox="-2.4 -2.4 28.80 28.80"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  transform="matrix(1, 0, 0, 1, 0, 0)"
  stroke="#4d91ff"
  strokeWidth="0.00024"
>
  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
  <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
  <g id="SVGRepo_iconCarrier">
    <g opacity="0.5">
      <path
        d="M14 2.75C15.9068 2.75 17.2615 2.75159 18.2892 2.88976C19.2952 3.02503 19.8749 3.27869 20.2981 3.7019C20.7852 4.18904 20.9973 4.56666 21.1147 5.23984C21.2471 5.9986 21.25 7.08092 21.25 9C21.25 9.41422 21.5858 9.75 22 9.75C22.4142 9.75 22.75 9.41422 22.75 9L22.75 8.90369C22.7501 7.1045 22.7501 5.88571 22.5924 4.98199C22.417 3.97665 22.0432 3.32568 21.3588 2.64124C20.6104 1.89288 19.6615 1.56076 18.489 1.40314C17.3498 1.24997 15.8942 1.24998 14.0564 1.25H14C13.5858 1.25 13.25 1.58579 13.25 2C13.25 2.41421 13.5858 2.75 14 2.75Z"
        fill="#ea5353"
      ></path>
      <path
        d="M2.00001 14.25C2.41422 14.25 2.75001 14.5858 2.75001 15C2.75001 16.9191 2.75289 18.0014 2.88529 18.7602C3.00275 19.4333 3.21477 19.811 3.70191 20.2981C4.12512 20.7213 4.70476 20.975 5.71085 21.1102C6.73852 21.2484 8.09318 21.25 10 21.25C10.4142 21.25 10.75 21.5858 10.75 22C10.75 22.4142 10.4142 22.75 10 22.75H9.94359C8.10583 22.75 6.6502 22.75 5.51098 22.5969C4.33856 22.4392 3.38961 22.1071 2.64125 21.3588C1.95681 20.6743 1.58304 20.0233 1.40762 19.018C1.24992 18.1143 1.24995 16.8955 1.25 15.0964L1.25001 15C1.25001 14.5858 1.58579 14.25 2.00001 14.25Z"
        fill="#ea5353"
      ></path>
      <path
        d="M22 14.25C22.4142 14.25 22.75 14.5858 22.75 15L22.75 15.0963C22.7501 16.8955 22.7501 18.1143 22.5924 19.018C22.417 20.0233 22.0432 20.6743 21.3588 21.3588C20.6104 22.1071 19.6615 22.4392 18.489 22.5969C17.3498 22.75 15.8942 22.75 14.0564 22.75H14C13.5858 22.75 13.25 22.4142 13.25 22C13.25 21.5858 13.5858 21.25 14 21.25C15.9068 21.25 17.2615 21.2484 18.2892 21.1102C19.2952 20.975 19.8749 20.7213 20.2981 20.2981C20.7852 19.811 20.9973 19.4333 21.1147 18.7602C21.2471 18.0014 21.25 16.9191 21.25 15C21.25 14.5858 21.5858 14.25 22 14.25Z"
        fill="#ea5353"
      ></path>
      <path
        d="M9.94359 1.25H10C10.4142 1.25 10.75 1.58579 10.75 2C10.75 2.41421 10.4142 2.75 10 2.75C8.09319 2.75 6.73852 2.75159 5.71085 2.88976C4.70476 3.02503 4.12512 3.27869 3.70191 3.7019C3.21477 4.18904 3.00275 4.56666 2.88529 5.23984C2.75289 5.9986 2.75001 7.08092 2.75001 9C2.75001 9.41422 2.41422 9.75 2.00001 9.75C1.58579 9.75 1.25001 9.41422 1.25001 9L1.25 8.90369C1.24995 7.10453 1.24992 5.8857 1.40762 4.98199C1.58304 3.97665 1.95681 3.32568 2.64125 2.64124C3.38961 1.89288 4.33856 1.56076 5.51098 1.40314C6.65019 1.24997 8.10584 1.24998 9.94359 1.25Z"
        fill="#ea5353"
      ></path>
    </g>
    <path
      d="M12 10.75C11.3096 10.75 10.75 11.3096 10.75 12C10.75 12.6904 11.3096 13.25 12 13.25C12.6904 13.25 13.25 12.6904 13.25 12C13.25 11.3096 12.6904 10.75 12 10.75Z"
      fill="#ea5353"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.89243 14.0598C5.29747 13.3697 5 13.0246 5 12C5 10.9754 5.29748 10.6303 5.89242 9.94021C7.08037 8.56222 9.07268 7 12 7C14.9273 7 16.9196 8.56222 18.1076 9.94021C18.7025 10.6303 19 10.9754 19 12C19 13.0246 18.7025 13.3697 18.1076 14.0598C16.9196 15.4378 14.9273 17 12 17C9.07268 17 7.08038 15.4378 5.89243 14.0598ZM9.25 12C9.25 10.4812 10.4812 9.25 12 9.25C13.5188 9.25 14.75 10.4812 14.75 12C14.75 13.5188 13.5188 14.75 12 14.75C10.4812 14.75 9.25 13.5188 9.25 12Z"
      fill="#ea5353"
    ></path>
  </g>
</svg>

      <h3 className="text-lg font-semibold">Полная Анонимность</h3>
      <p className="text-sm mt-2 text-gray-400">
        Мы не собираем личные данные и не отслеживаем активность. Ваши файлы защищены и доступны только вам.
      </p>
    </div>

    {/* Secure Storage Section */}
    <div className="flex flex-col items-center bg-gray-800 rounded-lg shadow-md p-6 transform hover:scale-105 hover:shadow-lg transition-transform duration-300 ease-in-out">
      <svg
      height="46px"
      viewBox="-1.44 -1.44 26.88 26.88"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      stroke="#c9c9c9"
      transform="rotate(0)"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="#CCCCCC"
        strokeWidth="0.192"
      ></g>
      <g id="SVGRepo_iconCarrier">
        <path
          d="M22 22L2 22"
          stroke="#42b355"
          strokeWidth="1.608"
          strokeLinecap="round"
        ></path>
        <path
          d="M3 22.0001V11.3472C3 10.4903 3.36644 9.67432 4.00691 9.10502L10.0069 3.77169C11.1436 2.76133 12.8564 2.76133 13.9931 3.77169L19.9931 9.10502C20.6336 9.67432 21 10.4903 21 11.3472V22.0001"
          stroke="#42b355"
          strokeWidth="1.608"
          strokeLinecap="round"
        ></path>
        <path
          d="M10 9H14"
          stroke="#42b355"
          strokeWidth="1.608"
          strokeLinecap="round"
        ></path>
        <path
          opacity="0.5"
          d="M9 15.5H15"
          stroke="#42b355"
          strokeWidth="1.608"
          strokeLinecap="round"
        ></path>
        <path
          opacity="0.5"
          d="M9 18.5H15"
          stroke="#42b355"
          strokeWidth="1.608"
          strokeLinecap="round"
        ></path>
        <path
          opacity="0.5"
          d="M18 22V16C18 14.1144 18 13.1716 17.4142 12.5858C16.8284 12 15.8856 12 14 12H10C8.11438 12 7.17157 12 6.58579 12.5858C6 13.1716 6 14.1144 6 16V22"
          stroke="#42b355"
          strokeWidth="1.608"
        ></path>
      </g>
    </svg>
      <h3 className="text-lg font-semibold">Распределенное Хранилище</h3>
      <p className="text-sm mt-2 text-gray-400">
        Ваши файлы надежно хранятся в сети IPFS, обеспечивая высокий уровень безопасности и доступности.
      </p>
    </div>
{/* Advances Encryption Section */}
<div className="flex flex-col items-center bg-gray-800 rounded-lg shadow-md p-6 transform hover:scale-105 hover:shadow-lg transition-transform duration-300 ease-in-out">
  <svg
    className="w-10 h-10 text-blue-400 mb-2"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M5 13l4 4L19 7"
    ></path>
  </svg>
  <h3 className="text-lg font-semibold">Целостность Данных</h3>
  <p className="text-sm mt-2 text-gray-400">
    <strong>Как это обеспечивает безопасность?</strong> Алгоритм SHA-256 не только защищает файлы от взлома, но и обеспечивает целостность данных. Если кто-то попытается изменить файл, новый хэш сразу выявит подмену. Это делает хранение на платформе безопасным и надёжным.
  </p>
</div>

    {/* 24/7 Uptime Section */}
    <div className="flex flex-col items-center bg-gray-800 rounded-lg shadow-md p-6 transform hover:scale-105 hover:shadow-lg transition-transform duration-300 ease-in-out">
       <svg
    fill="#3689e2"
    height="46px"
    viewBox="-6.4 -6.4 76.80 76.80"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    stroke="#3689e2"
    strokeWidth="0.00064"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" stroke="#999999" strokeWidth="0.64">
      <g>
        <path d="M46,22h-6.671l5.557-6.352C45.604,14.828,46,13.775,46,12.685V12.5c0-2.481-2.019-4.5-4.5-4.5S37,10.019,37,12.5V14h2v-1.5 c0-1.379,1.121-2.5,2.5-2.5s2.5,1.121,2.5,2.5v0.185c0,0.606-0.22,1.19-0.619,1.646L37,21.624V24h9V22z"></path>
        <path d="M54,24h2v-3h2v-2h-2V8h-2.618L48,18.764V21h6V24z M50.118,19L54,11.236V19H50.118z"></path>
        <path d="M50.405,32.627C57.596,31.061,63,24.653,63,17c0-8.822-7.178-16-16-16c-7.653,0-14.061,5.404-15.627,12.595 C29.608,13.207,27.807,13,26,13C12.215,13,1,24.215,1,38s11.215,25,25,25s25-11.215,25-25C51,36.193,50.793,34.392,50.405,32.627z M47,3c7.72,0,14,6.28,14,14s-6.28,14-14,14s-14-6.28-14-14S39.28,3,47,3z M44.961,37H43v2h1.949 c-0.235,4.484-2.024,8.553-4.847,11.688l-1.375-1.375l-1.414,1.414l1.375,1.375c-3.135,2.824-7.204,4.612-11.688,4.847V55h-2v1.949 c-4.484-0.235-8.553-2.024-11.688-4.847l1.375-1.375l-1.414-1.414l-1.375,1.375C9.074,47.553,7.286,43.484,7.051,39H9v-2H7.051 c0.235-4.484,2.024-8.553,4.847-11.688l1.375,1.375l1.414-1.414l-1.375-1.375c3.135-2.824,7.204-4.612,11.688-4.847V21h2v-1.961 c1.438,0.076,2.862,0.318,4.255,0.72c1.156,6.611,6.374,11.829,12.986,12.986C44.642,34.138,44.884,35.562,44.961,37z M26,61 C13.317,61,3,50.683,3,38s10.317-23,23-23c1.706,0,3.407,0.201,5.072,0.577C31.03,16.047,31,16.52,31,17 c0,0.209,0.023,0.413,0.031,0.621C29.381,17.212,27.695,17,26,17C14.421,17,5,26.421,5,38s9.421,21,21,21s21-9.421,21-21 c0-1.695-0.212-3.381-0.621-5.031C46.587,32.977,46.791,33,47,33c0.48,0,0.953-0.03,1.423-0.072C48.799,34.593,49,36.294,49,38 C49,50.683,38.683,61,26,61z"></path>
        <path d="M29,38c0-1.302-0.839-2.402-2-2.816V23h-2v12.184c-1.161,0.414-2,1.514-2,2.816c0,1.654,1.346,3,3,3 c0.462,0,0.894-0.113,1.285-0.301l7.008,7.008l1.414-1.414l-7.008-7.008C28.887,38.894,29,38.462,29,38z M25,38 c0-0.552,0.448-1,1-1s1,0.448,1,1s-0.448,1-1,1S25,38.552,25,38z"></path>
        <path d="M56,35c-1.654,0-3,1.346-3,3c0,1.251,0.771,2.324,1.862,2.773c-0.627,6.584-3.499,12.781-8.152,17.527l1.428,1.4 c4.999-5.098,8.074-11.763,8.725-18.842C58.095,40.485,59,39.353,59,38C59,36.346,57.654,35,56,35z M56,39c-0.552,0-1-0.448-1-1 s0.448-1,1-1s1,0.448,1,1S56.552,39,56,39z"></path>
        <path d="M6.283,16.733c4.658-4.321,10.651-6.978,16.949-7.579C23.686,10.237,24.755,11,26,11c1.654,0,3-1.346,3-3s-1.346-3-3-3 c-1.352,0-2.485,0.905-2.858,2.137c-6.771,0.624-13.214,3.489-18.218,8.13L6.283,16.733z M26,7c0.552,0,1,0.448,1,1s-0.448,1-1,1 s-1-0.448-1-1S25.448,7,26,7z"></path>
      </g>
    </g>
  </svg>
      <h3 className="text-lg font-semibold">Доступность 24/7</h3>
      <p className="text-sm mt-2 text-gray-400">
        Мы обеспечиваем круглосуточную доступность сервиса, чтобы ваши файлы были всегда под рукой.
      </p>
    </div>
  </div>
</div>

     {/*    <div>
      <h3 className="mb-5 text-lg font-medium text-gray-900 dark:text-white"> </h3>
      <ul className="grid w-full gap-6 md:grid-cols-3">
        <li>
          <input type="checkbox" id="react-option" className="hidden peer" required />
          <label
            htmlFor="react-option"
            className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
          >
            <div className="block">
                            <svg
                className="mb-2 w-7 h-7 text-green-400"
                fill="currentColor"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path d="M356.9 64.3H280l-56 88.6-48-88.6H0L224 448 448 64.3h-91.1z..."></path>
              </svg>
              <div className="w-full text-lg font-semibold">Рекламный Блок</div>
              <div className="w-full text-sm">Отправте запрос на info@photobunker.pro</div>
            </div>
          </label>
        </li>

        <li>
          <input type="checkbox" id="flowbite-option" className="hidden peer" />
          <label
            htmlFor="flowbite-option"
            className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
          >
            <div className="block">
              <svg
                className="mb-2 w-7 h-7 text-green-400"
                fill="currentColor"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path d="M356.9 64.3H280l-56 88.6-48-88.6H0L224 448 448 64.3h-91.1z..."></path>
              </svg>
              <div className="w-full text-lg font-semibold">Рекламный Блок</div>
              <div className="w-full text-sm">Отправте запрос на info@photobunker.pro</div>
            </div>
          </label>
        </li>

        <li>
          <input type="checkbox" id="angular-option" className="hidden peer" />
          <label
            htmlFor="angular-option"
            className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
          >
            <div className="block">
                  <svg
                className="mb-2 w-7 h-7 text-green-400"
                fill="currentColor"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path d="M356.9 64.3H280l-56 88.6-48-88.6H0L224 448 448 64.3h-91.1z..."></path>
              </svg>
               <div className="w-full text-lg font-semibold">Рекламный Блок</div>
              <div className="w-full text-sm">Отправте запрос на info@photobunker.pro</div>
            </div>
          </label>
        </li>
      </ul>
    </div>*/}
   <section className="mt-20 w-full flex flex-col items-center bg-white-800 p-10 text-white my-5 items-start white-glassmorphism p-3 m-2 cursor-pointer hover:shadow-xl mt-10">
        <h2 className="text-2xl font-bold mb-4 text-gradient">Почему IPFS?</h2>
        <p className="text-white text-base text-center mx-2 text-center mb-4 text-white py-2 text-gradient">
          IPFS (InterPlanetary File System) — это децентрализованный протокол, который позволяет эффективно хранить и делиться данными. Благодаря IPFS, ваши файлы будут доступны в любое время, из любой точки мира, без рисков утраты данных.
        </p>
      </section>
         <section className="mt-5 w-full flex flex-col items-center bg-white-800 p-10 text-white my-20 items-start white-glassmorphism p-3 m-2 cursor-pointer hover:shadow-xl mt-10">
        <h2 className="text-2xl font-bold mb-4 text-gradient">Удаление Метаданных</h2>
        <p className="text-white text-base text-center mx-2 text-center mb-4 text-white py-2 text-gradient">
          Загружаемый файл отчищается от таких метаданных как геолокация, телефон, разрешение девайса и прочих дополнительных данных, гарантируя анонимность источника создания
        </p>
      </section>
      {/* Footer Section */}
      <footer className="w-full flex md:justify-center justify-between items-center flex-col p-4 bg-gradient-to-r from-gray-700 to-gray-900 mt-10">
        <div className="w-full flex sm:flex-row flex-col justify-between items-center my-4">
          <div className="flex flex-[0.5] justify-center items-center">
          <div className="flex flex-1 justify-evenly items-center flex-wrap sm:mt-0 mt-5 w-full ">

          <div className="w-10 h-10 rounded-full flex justify-center items-center bg-[#2952E3]">
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 16 16"
              fontSize="21"
              className="text-white"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M8 0c-.69 0-1.843.265-2.928.56-1.11.3-2.229.655-2.887.87a1.54 1.54 0 0 0-1.044 1.262c-.596 4.477.787 7.795 2.465 9.99a11.777 11.777 0 0 0 2.517 2.453c.386.273.744.482 1.048.625.28.132.581.24.829.24s.548-.108.829-.24a7.159 7.159 0 0 0 1.048-.625 11.775 11.775 0 0 0 2.517-2.453c1.678-2.195 3.061-5.513 2.465-9.99a1.541 1.541 0 0 0-1.044-1.263 62.467 62.467 0 0 0-2.887-.87C9.843.266 8.69 0 8 0zm2.146 5.146a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 7.793l2.646-2.647z"
              ></path>
            </svg>
                        <p className="text-white text-4xl sm:text-5xl py-2 text-gradient">Фото Бункер</p>

          </div>
          </div>
          </div>
          <div className="flex flex-1 justify-evenly items-center flex-wrap sm:mt-0 mt-5 w-full ">
          </div>
        </div>
         <div className="flex flex-[0.1] justify-end items-center space-x-4">
      {/* Telegram Icon */}
      <a href="https://t.me/photobunker_bot" target="_blank" rel="noopener noreferrer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          aria-label="Telegram"
          viewBox="0 0 512 512"
          className="w-8 h-8 cursor-pointer hover:opacity-75"
        >
          <rect width="512" height="512" fill="#37aee2" rx="15%"></rect>
          <path fill="#c8daea" d="M199 404c-11 0-10-4-13-14l-32-105 245-144"></path>
          <path fill="#a9c9dd" d="M199 404c7 0 11-4 16-8l45-43-56-34"></path>
          <path
            fill="#f6fbfe"
            d="M204 319l135 99c14 9 26 4 30-14l55-258c5-22-9-32-24-25L79 245c-21 8-21 21-4 26l83 26 190-121c9-5 17-3 11 4"
          ></path>
        </svg>
      </a>

      {/* APK Download Icon */}
      <a href="#">
        <img
          src="https://photobunker.pro/android.png"
          style={{ height: '146px', width: 'auto' }}
          alt="Download Android APK"
          className="w-8 h-8 cursor-pointer hover:opacity-75"
        />
      </a>
    </div>     
        <div className="flex justify-center items-center flex-col mt-5">
          <p className="text-white text-sm text-center">Бесплатное хранилище нового поколения для ваших медиафайлов</p>
          <p className="text-white text-sm text-center font-medium mt-2">support@photobunker.pro</p>
        </div>
        <div className="sm:w-[90%] w-full h-[0.25px] bg-gray-400 mt-5 "></div>
        <div className="sm:w-[90%] w-full flex justify-between items-center mt-3">
          <p className="text-white text-left text-xs">Фото Бункер 2024</p>
          <p className="text-white text-right text-xs">All rights reserved</p>
        </div>
      </footer>
    </div>
 )}
    </>
  );
};

export default UploadService;
